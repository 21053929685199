import { template as template_faa29bf4e66b43c09e3ac423de126c75 } from "@ember/template-compiler";
const WelcomeHeader = template_faa29bf4e66b43c09e3ac423de126c75(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
