import { template as template_4db00a85bf834d1cba8971dff81cd4e2 } from "@ember/template-compiler";
const FKLabel = template_4db00a85bf834d1cba8971dff81cd4e2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
