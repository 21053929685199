import { template as template_4743b5bec6da4849934df58a3d06f8fb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_4743b5bec6da4849934df58a3d06f8fb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
