import { template as template_370044f3ae0b482da1fe9ba8ab2a2f5a } from "@ember/template-compiler";
import EmojiPickerContent from "discourse/components/emoji-picker/content";
const EmojiPickerDetached = template_370044f3ae0b482da1fe9ba8ab2a2f5a(`
  <EmojiPickerContent
    @close={{@close}}
    @term={{@data.term}}
    @didSelectEmoji={{@data.didSelectEmoji}}
    @context={{@data.context}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmojiPickerDetached;
